export const environment = {
    name: 'dev',
    production: false,
    debugForm: false,
    api: {
        beyondRegistration: {
            invokeUrl: 'https://phoenix-okta.genesyscsdteng.com/dev',
            api: 'wt7FNlwrtQ8h1QVTWrWfk8foLq937lbstQVJtH5e',
            authorizationToken: 'SSWS 00T1Bkwf7xpR1R0ti8qyIrn-w8aq4ywyq2pBDtrvx9',
            userGroupIds: ['00g24c5exykOExlgh0h8'],
        },
        captcha: {
            scriptUri: 'https://88a1e4ee2fbc.us-east-1.captcha-sdk.awswaf.com/88a1e4ee2fbc/jsapi.js',
            api: 'GFsQZ10MUFvRyua8iyy2rqbl/vKslwf0MlwK89hzDOfs+/ZfFkUC9sEPbmHef+jqWJw2epn3SLp7NC1XzAgr9Q+LAqeAmHOcEljcv2w9eDztPPxvqu+nwDryCV8e4oOHNMMdNGLw+FUVxSz9uZ23QnrA4JN9klzB0C/1Is2DBkH21wHGExrvjo3XdTGKxYnrO9i0rAWZNcdSeuvNgkZl134CxMh5yufXlQe/U4oFJeVv5fqVLXFJF177HNMy3WMfIGhb6JCHC2Kb3AVZid7GqwDlHl6xEydB1cvEcb7yT0p2rTeE1Du4RMbRCsEqTyaQYI5sDIK6HH2jbImFA2Sh2xLUWEGtWreYK7lNudhSYyzR9PVhEDmc4g06bwACa87WFxl8dSyaM68BKn7nV3lEtvDOX5Zn3M0F19+7mmo0Vld6WAhlNbgvWQWjRp1hYXKADdhNgTlgKUpZpXNveGfgiTje2dbZkjdXjTdie0KCdS0l20TmOAcZNMZ/qW5hIixvscuqy3q9S4ACJLO6dQxv26DBW1z25qr8EciBDU7dwsEBwodZUsKj1GjmjB7lyLhLNHV4OO7mPRDVsNa8L62/tVtQwwP2y17Bnfowwya3sEqmuQSpaYC+sEyqTFOKa/FOz2A6TNKFskidXuj6CbF2u6xup7PXalLcvcSp13g/0eo=_0_1',
        },
    },
    beyond: {
        url: 'https://beyond-dev.genesyscsdteng.com/explore',
    },
};
