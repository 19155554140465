import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { productSupportRegions } from './product-support-modal.data';
import { ProductSupportRegion } from './product-support-modal.interface';

@Component({
    selector: 'app-product-support-modal',
    templateUrl: './product-support-modal.component.html',
    styleUrls: ['./product-support-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule],
})
export class ProductSupportModalComponent {
    regions: ProductSupportRegion[] = productSupportRegions;
}
