import { Routes } from '@angular/router';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { BeyondRegistrationComponent } from '@pages/beyond-registration/beyond-registration.component';
import { UserRegistrationComponent } from '@pages/user-registration/user-registration.component';
import { VoucherRegistrationComponent } from '@pages/voucher-registration/voucher-registration.component';

export const routes: Routes = [
    {
        path: 'voucher-registration',
        pathMatch: 'full',
        component: VoucherRegistrationComponent,
    },
    {
        path: 'beyond-registration',
        pathMatch: 'full',
        component: BeyondRegistrationComponent,
    },
    {
        path: 'user-registration',
        pathMatch: 'full',
        component: UserRegistrationComponent,
    },
    {
        path: '',
        redirectTo: 'beyond-registration',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];
