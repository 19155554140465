<body>
    <div class="content">
        <div class="wrapper">
            <div class="columns">
                <div class="about">
                    <h3>Create your</h3>
                    <h2>Genesys Login</h2>

                    <p class="description-container">
                        Request your <span class="large-font">FREE</span> Genesys account to get started! <br />This
                        single sign-on is used for all things Genesys.
                    </p>
                    <div class="sign-in-container">
                        <span class="large-font">Already have an account? Sign in above.</span>
                    </div>
                    <div class="container">
                        <span class="custom-text"> Have an urgent issue? </span>
                        <button class="product-support custom-text" (click)="openProductSupportModal()">
                            Contact Product Support
                            <span class="right-arrow"></span>
                        </button>
                    </div>
                </div>
                <div class="form-content">
                    <app-okta-registration-form
                        [formDescription]="'A business email address is required.'"
                        [formDescriptionCustomCss]="'large-font'">
                    </app-okta-registration-form>
                </div>
            </div>
        </div>
    </div>
</body>
