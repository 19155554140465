<body>
    <app-header></app-header>
    <div class="content">
        <div class="wrapper">
            <h2>Your learning journey begins today.</h2>
            <h3>Request a complimentary Genesys Cloud Core subscription to get started!</h3>

            <div class="columns">
                <div class="about">
                    This free-to-explore learning subscription gives you access to a wide variety of coursework and
                    engaging content, covering key topics such as:

                    <ul
                        ><li>Agent and Supervisor Focused Training</li>
                        <li>Genesys Cloud Basics</li>
                        <li>AI Fundamentals</li>
                        <li>WEM Introduction</li>
                        <li>Journey Management</li>
                        <li>Technical Webinars</li>
                        <li>And more!</li></ul
                    >

                    <p>
                        Beyond's diverse curriculum and personalized learning paths are designed to support all roles,
                        skill levels and learning types. No matter your focus, you'll find relevant, practical training
                        to meet both your professional and organizational goals.</p
                    >
                    <h3
                        >Request your exclusive, no-cost subscription today! &nbsp;&nbsp;<img
                            src="assets/images/arrowright.svg"
                            height="30px"
                            width="50px"
                    /></h3>
                    <p><strong>Already a Genesys Beyond subscriber?</strong></p>
                    <p>
                        <a href="{{ beyondLoginUrl }}" target="_blank">Sign in</a>
                        to explore and enroll in our new learning paths.
                    </p>
                </div>
                <div class="form-content">
                    <app-okta-registration-form
                        [formDescription]="
                            'Request a Genesys Cloud Core subscription by completing the form below. A business email address is required.'
                        ">
                    </app-okta-registration-form>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</body>
