<div class="footer">
    <div class="wrapper">
        <!-- Left side content -->
        <div class="left-content">
            <div class="footer-logo">
                <img src="/assets/images/genesys-logo.svg" alt="Genesys Logo" />
            </div>

            <div class="footer-links">
                <p>
                    <span>Copyright &copy; {{ currentYear }}&nbsp;Genesys. </span>
                    <span>All rights reserved.</span>
                    <span class="links-container">
                        <a
                            href="https://www.genesys.com/company/legal/terms-of-use"
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                            >Terms of Use</a
                        >
                        <a
                            href="https://www.genesys.com/company/legal/privacy-policy"
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                            >Privacy Policy</a
                        >
                    </span>
                </p>
            </div>
        </div>

        <!-- Right side social links -->
        <div class="social-links">
            <a
                href="http://www.linkedin.com/company/601919?trk=tyah"
                rel="noopener noreferrer nofollow"
                title="Genesys Linkedin"
                class="linkedin"
                aria-label="Genesys Linkedin"
                target="_blank">
                <img src="/assets/images/social-icons/linkedin.svg" alt="Linkedin" />
            </a>
            <a
                href="http://www.instagram.com/genesyscx"
                rel="noopener noreferrer nofollow"
                title="Genesys Instagram"
                class="instagram"
                aria-label="Genesys Instagram"
                target="_blank">
                <img src="/assets/images/social-icons/instagram.svg" alt="Instagram" />
            </a>
            <a
                href="https://twitter.com/Genesys"
                rel="noopener noreferrer nofollow"
                title="Genesys Twitter"
                class="twitter"
                aria-label="Genesys Twitter"
                target="_blank">
                <img src="/assets/images/social-icons/twitter.svg" alt="Twitter" />
            </a>
            <a
                href="http://www.facebook.com/genesys"
                rel="noopener noreferrer nofollow"
                title="Genesys Facebook"
                class="facebook"
                aria-label="Genesys Facebook"
                target="_blank">
                <img src="/assets/images/social-icons/facebook.svg" alt="Facebook" />
            </a>
            <a
                href="https://www.youtube.com/user/Genesyslab1"
                rel="noopener noreferrer nofollow"
                title="Genesys Youtube"
                class="youtube"
                aria-label="Genesys Youtube"
                target="_blank">
                <img src="/assets/images/social-icons/youtube.svg" alt="Youtube" />
            </a>
            <a
                href="https://www.genesys.com/blog"
                rel="noopener noreferrer nofollow"
                title="Genesys Blog"
                class="blog"
                aria-label="Genesys Blog"
                target="_blank">
                <img src="/assets/images/social-icons/wordpress.svg" alt="Blogs" />
            </a>
        </div>
    </div>
</div>
