import { ProductSupportRegion } from './product-support-modal.interface';

export const productSupportRegions: ProductSupportRegion[] = [
    {
        label: 'North America (USA & Canada)',
        contacts: [
            {
                label: 'Toll-free, N. America',
                phoneNumbers: ['+1-888-369-5555'],
            },
            {
                label: 'United States',
                phoneNumbers: ['+1-317-715-8600'],
            },
            {
                label: 'Collector',
                phoneNumbers: ['+1-866-396-2599', '+1-317-715-8448'],
            },
        ],
    },
    {
        label: 'Europe, Middle East, Africa',
        contacts: [
            {
                label: 'International',
                phoneNumbers: ['+44 (0) 127-645-7002'],
            },
            {
                label: 'Netherlands',
                phoneNumbers: ['+31 35 369 0201'],
            },
            {
                label: 'Saudi Arabia',
                phoneNumbers: ['+966 (0) 112-502-998'],
            },
        ],
    },
    {
        label: 'Asia Pacific',
        contacts: [
            {
                label: 'International',
                phoneNumbers: ['+63-2-8864-3333'],
            },
            {
                label: 'Australia',
                phoneNumbers: ['+61-7-3368-6868'],
            },
            {
                label: 'Toll-free, India',
                phoneNumbers: ['+000-800-100-7136'],
            },
            {
                label: 'Japan (Japanese)',
                phoneNumbers: ['+81-3-5989-1450'],
            },
            {
                label: 'Toll-free, Malaysia',
                phoneNumbers: ['+1-800-814-472'],
            },
            {
                label: 'Korea (Korean)',
                phoneNumbers: ['+82-80-822-1466'],
            },
            {
                label: 'New Zealand',
                phoneNumbers: ['+64-9-950-8476'],
            },
        ],
    },
    {
        label: 'Latin America',
        contacts: [
            {
                label: 'International',
                phoneNumbers: ['+55-11-4040-4849'],
            },
            {
                label: 'Brazil (Portuguese)',
                phoneNumbers: ['+55-11-3882-0338'],
            },
            {
                label: 'Toll-free, Colombia',
                phoneNumbers: ['018005183954'],
            },
            {
                label: 'Toll-free, Ecuador',
                phoneNumbers: ['1-800-000-272'],
            },
            {
                label: 'Toll-free, Mexico',
                phoneNumbers: ['1-800-112-2660'],
            },
            {
                label: 'Toll-free, Panama',
                phoneNumbers: ['00800226-8420'],
            },
            {
                label: 'Toll-free, Peru',
                phoneNumbers: ['080077301'],
            },
        ],
    },
];
