import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal'; // Add this import
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { OktaRegistrationFormComponent } from '@components/okta-registration-form/okta-registration-form.component';
import { ProductSupportModalComponent } from '@components/product-support-modal/product-support-modal.component';

@Component({
    selector: 'app-user-registration',
    providers: [],
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss'],
    standalone: true,
    imports: [CommonModule, OktaRegistrationFormComponent, MatButtonModule, MatDialogModule],
})
export class UserRegistrationComponent {
    constructor(
        private dialog: MatDialog,
        private overlay: Overlay,
    ) {}

    /**
     * Opens a product support modal dialog from the top of the viewport.
     * This is specifically configured for host environment where dialog needs to appear
     * at the top instead of Material's default center position.
     *
     * Configuration includes:
     * - Fixed width of 1441px
     * - Top positioning with 10px offset
     * - Blocks background scrolling
     * - Custom animation origin from top
     */
    openProductSupportModal(): void {
        const dialogConfig = new MatDialogConfig();
        Object.assign(dialogConfig, {
            width: '1441px',
            position: {
                top: '50px', // Forces dialog to open from top instead of center
            },
            panelClass: 'top-dialog',
            autoFocus: true,
            transformOrigin: 'center top', // Sets the transform origin to top for proper animation
            scrollStrategy: this.overlay.scrollStrategies.block(), // Prevents background scrolling
        });

        const dialogComponent: ComponentType<ProductSupportModalComponent> = ProductSupportModalComponent;
        const dialogRef = this.dialog.open(dialogComponent, dialogConfig);

        // Additional positioning adjustment after dialog is opened
        dialogRef.afterOpened().subscribe(() => {
            const dialogElement = document.querySelector('.top-dialog');
            if (dialogElement) {
                dialogRef.updatePosition({
                    top: '50px', // Ensures consistent top positioning
                });
            }
        });
    }
}
