<header>
    <h3 mat-dialog-title class="h3">Regional Product Support</h3>
    <mat-icon mat-icon-button mat-dialog-close class="close-button">close</mat-icon>
</header>

<mat-dialog-content #dialogContent>
    <div class="header-text">
        <p class="p-body-large"> Please use these regional phone numbers to reach Product Support. </p>
    </div>
    <div class="content-wrapper">
        <div class="left">
            <mat-accordion displayMode="flat">
                <mat-expansion-panel *ngFor="let region of regions">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="sub-title-3">{{ region.label }}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul>
                        <li *ngFor="let contact of region.contacts" class="p-body">
                            {{ contact.label }}:
                            <span class="p-content">
                                {{ contact?.phoneNumbers?.join(', ') }}
                            </span>
                        </li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="right">
            <div class="instructions">
                <p>
                    All customers and partners will be asked to provide their <span>personal PIN</span>. To locate your
                    PIN, login to <a href="https://genesys.my.site.com/customercare" target="_blank">My Support</a> and
                    click the drop down arrow by your username, select <span>'My Support Profile'</span>.
                </p>
                <p>
                    Genesys <span>FedRAMP customers</span> can view contact information as well as submit a case via the
                    <a href="https://www.genesys.com/trust/fedramp-authorization" target="_blank"
                        >FedRAMP My Support Portal</a
                    >. For access requests/issues, please email
                    <a href="mailto:customercare-gov@genesys.com">customercare-gov&#64;genesys.com</a>
                </p>
            </div>
        </div>
    </div>
</mat-dialog-content>
